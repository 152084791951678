
import { mapState, mapActions } from 'vuex';
import Slider from '@/components/common/Slider.vue';
import HeatMeterMarker from '@/components/menu/HeatMeterMarker.vue'
import CustomProductModifier from '@/components/menu/CustomProductModifier.vue'

const DefaultHeatLevels = [
  {
    name: "No Spice",
    default: true,
    meta: {
      hex_color_code: "#FBCB01",
      heat_meter_step: '1'
    },
  },
  {
    name: "Lite Mild",
    meta: {
      hex_color_code: "#F0AE1E",
      heat_meter_step: '2'
    },
  },
  {
    name: "Mild",
    meta: {
      hex_color_code: "#E58E01",
      heat_meter_step: '3'
    },
  },
  {
    name: "Medium",
    meta: {
      hex_color_code: "#D96C01",
      heat_meter_step: '4'
    },
  },
  {
    name: "Hot",
    meta: {
      hex_color_code: "#CD4B01",
      heat_meter_step: '5'
    },
  },
  {
    name: "Extra Hot",
    meta: {
      hex_color_code: "#C12901",
      heat_meter_step: '6'
    },
  },
  {
    name: "Reaper",
    meta: {
      hex_color_code: "#B20101",
      heat_meter_step: '7'
    },
  }
]
      

export default {
  name: 'HeatMeter',
  props: {
    mods: Array,
    heatLevels: {
      type: Array,
      default: () => ([...DefaultHeatLevels]),
    }
  },
  components: {
    Slider,
    HeatMeterMarker,
    CustomProductModifier,
  },
  data() {
    return {
      selected_level_1: 0,
      selected_level_2: 0,
    }
  },
  computed: {
    ...mapState({
      choices: state => state.product.choices,
      isEdit: state => state.product.isEdit
    }),
    options() {
      return {
        dotSize: 34,
        width: 8,
        height: 300,
        direction: 'vertical',
        min: 0,
        max: this.heatLevelsForDisplay ? (this.heatLevelsForDisplay.length > 0 ? this.heatLevelsForDisplay.length - 1 : 0) : 0,
        interval: 1,
        tooltip: 'none',
      }
    },
    isSameLevel() {
      return this.hasSecondaryItem && this.selected_level_1 === this.selected_level_2
    },
    heatLevelsForDisplay() {
      const hasMeta = this.heatLevels?.every(item => item.meta.hasOwnProperty('hex_color_code') && item.meta.hasOwnProperty('heat_meter_step'));
      if(hasMeta) {
        const copiedHeatLevels = this.$utils.copy(this.heatLevels)

        return copiedHeatLevels.sort((a, b) => a.meta.heat_meter_step - b.meta.heat_meter_step);
      } else {
        return [...DefaultHeatLevels];
      }
    },
    slider_1_color() {
      if(this.heatLevelsForDisplay && this.heatLevelsForDisplay.length > 0) {
        return this.heatLevelsForDisplay[this.selected_level_1].meta.hex_color_code
      }
    },
    slider_2_color() {
      if(this.heatLevelsForDisplay && this.heatLevelsForDisplay.length > 0) {
        return this.heatLevelsForDisplay[this.selected_level_2].meta.hex_color_code
      }
    },
    slider_mods() {
      if (this.mods && this.mods.length > 0) {
        return this.mods.filter(item => 
          (item.meta && (item.meta.heat_meter_primary || item.meta.heat_meter_secondary))
        );
      }
      return [];
    },
    button_mods() {
      if (this.mods && this.mods.length > 0) {
        return this.mods.filter(item => 
          (item.meta && (item.meta.heat_meter_primary_mod || item.meta.heat_meter_secondary_mod))
        );
      }
      return [];
    },
    hasSecondaryItem() {
      return this.mods?.some(mod => mod.meta.hasOwnProperty('heat_meter_secondary'));
    },
  }, 
  watch: {
    selected_level_1(val) {
      if(val !== null && this.slider_mods?.length) {
        const option = this.slider_mods[0].options[val] 
        this.select(option)
      }
    },
    selected_level_2(val) {
      if(val !== null && this.slider_mods?.length === 2) {
        const option = this.slider_mods[1].options[val] 
        this.select(option)
      }
    },
  },
  methods: {
    ...mapActions({
      selectMod: 'product/selectMod',
      removeMod: 'product/removeMod',
    }),
    async select(option) {
      if(!option) return;
      try {
        const found = this.choices?.find(choice => choice.id === option?.choice_id);

        if (found && !this.isEdit) {
          this.removeMod({ ...option, id: option?.choice_id });
        } else {
          await this.selectMod({ 
            ...option, 
            id: option?.choice_id, 
            quantity: 1 
          });
        }
      } catch (error) {
        console.error('An error occurred in select method:', error);
      }
    },
    selectDefaultValues() {
      if(this.slider_mods?.length > 0) {
        //Primary
        this.slider_mods[0].options.forEach((option,i)  => {
          if(option.default) {
            this.selected_level_1 = i
            this.select(option)
          }
        });
        
          
        //Secondary
        if(this.slider_mods.length === 2) {
          this.slider_mods[1].options.forEach((option,i)  => {
            if(option.default) {
              this.selected_level_2 = i
              this.select(option)
            }
          });
        }

        if(this.choices?.length == 0) {
          this.selected_level_1 = 0
          this.select(this.slider_mods[0].options[0])
          
          if(this.hasSecondaryItem) {
            this.selected_level_2 = 0
            this.select(this.slider_mods[1].options[0])
          }
        }
      }
    }
  },
  mounted() {
    try {
      if (!this.isEdit) {
        this.selectDefaultValues();
      } else {
        if (this.slider_mods && this.slider_mods.length > 0 && this.slider_mods[0].options) {
          const option1 = this.slider_mods[0].options.find(option => option.name === this.choices[0].name);
          if (option1) {
            this.selected_level_1 = this.slider_mods[0].options.indexOf(option1);
          }
        }
        if (this.slider_mods && this.slider_mods.length > 1 && this.slider_mods[1].options) {
          const option2 = this.slider_mods[1].options.find(option => option.name === this.choices[1].name);
          if (option2) {
            this.selected_level_2 = this.slider_mods[1].options.indexOf(option2);
          }
        }
      }
    } catch (error) {
      console.error('An error occurred in HeatMeter mounted method:', error);
    }
  }
}
