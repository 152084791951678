import { render, staticRenderFns } from "./CheckoutPageWeb.vue?vue&type=template&id=af50fc08&"
import script from "./CheckoutPageWeb.vue?vue&type=script&lang=js&"
export * from "./CheckoutPageWeb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonAccordionItem: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/AccordionItem/index.vue').default,CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default,GlobalsLayoutSitePopup: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/_globals/layout/SitePopup.vue').default})
