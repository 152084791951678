import { render, staticRenderFns } from "./CustomLocationCard.vue?vue&type=template&id=a3ab84b6&"
import script from "./CustomLocationCard.vue?vue&type=script&lang=js&"
export * from "./CustomLocationCard.vue?vue&type=script&lang=js&"
import style0 from "./CustomLocationCard.vue?vue&type=style&index=0&id=a3ab84b6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonIcon: require('/opt/build/repo/node_modules/@pleinair/bigcolony-vue/components/common/Icon/index.vue').default})
